import React from "react";
// reactstrap components
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  CardImgOverlay,
  Table,
} from "reactstrap";

import Image  from 'gatsby-image';
import {Link} from 'gatsby'

function SectionRow({title, path, status}){

  let statusClass;
  switch(status){
    case 'not started':
      statusClass = 'bg-default';
      break;
    case 'in progress':
      statusClass = 'bg-info';
      break;
    case 'to do':
      statusClass = 'bg-danger';
      break;
    case 'completed':
      statusClass = 'bg-success';
      break;
    case 'not ready':
      statusClass = 'bg-light';
  }

  let link;
  if(status === 'not ready'){
    link = (
      <span className="font-weight-bold text-light">{ title }</span>
    );
  }
  else{
    link = (
      <Link to={path} className="font-weight-bold section-link">
        { title }
      </Link>
    );
  }
  return (
    <tr>
      <td>
        { link }
      </td>
      <td>
        <Badge color="" className="badge-dot mr-4">
          <i className={statusClass} />
          <span className="status">{status}</span>
        </Badge>
      </td>
    </tr>
  )

}

const ChapterImage = props => {
  return (
      <Image
        fluid={props.img.fluid}
        alt={props.img.alt}
        tag="Image"
        className={'card-img-top'}
        top
      />
  )
}

const CompletedChapterImage = props => {
  const completedStyle = {
    backgroundImage: 'linear-gradient(to left, rgba(45,206,137,0), rgba(45,206,137,1))',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
  };

  const textStyle = {
    marginTop: '1.5rem',
    marginLeft: '1.5rem',
  }
  return (
    <div style={{position: 'relative'}}>
      <ChapterImage img={props.img} />
      <div style={completedStyle}>
        <div style={textStyle} className="h2 text-white">
          <i className="ni ni-check-bold"></i>COMPLETE!
        </div>
      </div>
    </div>
  )
}

const IncompleteChapterImage = props => {
  return (
    <ChapterImage img={props.img} />
  )
}

/**
 *
 * @param {object} img
 * @param img.fluid
 * @param img.alt
 * @param chapterHeader
 * @param chapterTitle
 * @param sectionColumnLabel
 * @param statusColumnLabel
 * @param {array} sections
 * @returns {*}
 * @constructor
 */
function ChapterCard({img, chapterHeader, chapterTitle, sectionColumnLabel, statusColumnLabel, sections, isComplete}){
  const sectionRows = sections.map((section) => (
    <SectionRow key={section.id} title={section.title} status={section.status} path={section.path}/>
  ));

  const gradientStyle = isComplete ? {
    backgroundImage: 'linear-gradient(to left, rgba(45,206,137,0), rgba(45,206,137,1))'
  } : {};

  return (
    <Card>
      {
        isComplete ? (
          <CompletedChapterImage img={img} />
        ) : (
          <IncompleteChapterImage img={img} />
        )
      }
      <CardBody>
        <small className="text-muted">
          {chapterHeader}
        </small>
        <CardTitle className="h2 mb-0">
          {chapterTitle}
        </CardTitle>

      </CardBody>
      <Table className="overflow-hidden align-items-center table-flush" hover responsive>
        <thead className="thead-light">
        <tr>
          <th>{sectionColumnLabel}</th>
          <th>{statusColumnLabel}</th>
        </tr>
        </thead>
        <tbody>{sectionRows}</tbody>
      </Table>
    </Card>
  );
}
export default ChapterCard;
