import React from "react";
import {
  AlignmentType, 
  Document, 
  HeadingLevel,
  HorizontalPositionAlign, 
  HorizontalPositionRelativeFrom,
  Media, 
  Packer,
  PageBreak, 
  Paragraph,
  StyleLevel,
  Table, 
  TableRow, 
  TableCell, 
  TabStopType, 
  TabStopPosition,
  TableOfContents, 
  TextRun,
  TextWrappingType, 
  UnderlineType,
  VerticalAlign, 
  VerticalPositionAlign,
  VerticalPositionRelativeFrom 
} from 'docx';
import {parse, stringify} from 'himalaya';
import { decode } from 'he';
// process the replacements in this util
import { doReplacements } from '../utils/replacements';
// streamsaver
import streamSaver from 'streamsaver';
import 'web-streams-polyfill/dist/ponyfill.es6.mjs';
import probe from 'probe-image-size';

const axios = require('axios');

const docConfig = {
	creator: "Policy Wizard",
	title: "",
	description: "",
	styles: {
	paragraphStyles: [
		{
			id: "ImagineHeading1",
			name: "Heading 1",
			basedOn: HeadingLevel.HEADING_1,
			next: HeadingLevel.HEADING_1,
			quickFormat: true,
			run: {
        font: "Calibri Light",
				italics: false,
        color: '000000',
        size: 72
			},
      paragraph: {
        spacing: {
          before: 480,
          after: 120 
        }
      }
		},
		{
			id: "ImagineHeading2",
			name: "Heading 2",
			basedOn: HeadingLevel.HEADING_2,
			next: HeadingLevel.HEADING_2,
			quickFormat: true,
			run: {
        font: "Calibri Light",
				italics: false,
        color: '0091B3',
        size: 32
			},
      paragraph: {
        spacing: {
          before: 360,
          after: 120
        }
      }
		},
		{
			id: "ImagineHeading3",
			name: "Heading 3",
			basedOn: HeadingLevel.HEADING_3,
			next: HeadingLevel.HEADING_3,
			quickFormat: true,
			run: {
        font: "Calibri Light",
				italics: false,
        color: '000000',
        size: 28,
        allCaps: true
			},
      paragraph: {
        spacing: {
          before: 360,
          after: 120
        }
      }
		},
		{
			id: "ImagineHeading4",
			name: "Heading 4",
			basedOn: HeadingLevel.HEADING_4,
			next: HeadingLevel.HEADING_4,
			quickFormat: true,
			run: {
        font: "Calibri",
				italics: false,
        color: '000000',
        size: 28
			},
      paragraph: {
        spacing: {
          before: 240,
          after: 120
        }
      }
		},
		{
			id: "ImagineHeading5",
			name: "Heading 5",
			basedOn: HeadingLevel.HEADING_5,
			next: HeadingLevel.HEADING_5,
			quickFormat: true,
			run: {
        font: "Calibri",
				italics: false,
        color: '000000',
        size: 28
			},
		},
		{
			id: "ImagineHeading6",
			name: "Heading 6",
			basedOn: HeadingLevel.HEADING_6,
			next: HeadingLevel.HEADING_6,
			quickFormat: true,
			run: {
        font: "Calibri",
				italics: false,
        color: '000000',
        size: 28
			},
		},
	],
	}
};

// base objects for each html element that could be present in the doc
const tags = {
  h1: {style: "ImagineHeading1", text: '', font: { name: "Calibri Light" }},
  h2: {style: "ImagineHeading2", text: '', font: { name: "Calibri Light" }},
  h3: {style: "ImagineHeading3", text: '', font: { name: "Calibri Light" }},
  h4: {style: "ImagineHeading4", text: '', font: { name: "Calibri Light" }},
  h5: {style: "ImagineHeading5", text: '', font: { name: "Calibri Light" }},
  h6: {style: "ImagineHeading6", text: '', font: { name: "Calibri Light" }},
  ul: {children: []},
  ol: {children: []}, 
  li: {bullet: { level: 0 }, size: 24, color: '000000', text: '', font: { name: "Calibri Light" }, children: []},
  em: {bold: true, size: 24, text: '', font: { name: "Calibri Light" }},
  strong: {bold: true, size: 24, text: '', font: { name: "Calibri Light" }},
  br: {break: {}},
  span: {text: '', size: 24, font: { name: "Calibri Light" }},
  p: {children: [], size: 24, font: { name: "Calibri Light" }},
  tr: {children: [], size: 24, font: { name: "Calibri Light" }},
  td: {children: [], size: 24, font: { name: "Calibri Light" }},
  th: {children: [], size: 24, font: { name: "Calibri Light" }},
};

const headers = ['h1','h2','h3','h4','h5','h6'];

// top level docx functions
// these are the starting points for all docx generation
function docxExport(data, book, userData, event) {
  const chapters = data.allNodeDashboard.edges[0].node.relationships.field_chapters;
  let body = doReplacements(chapters, userData, data[book].body.value);
  body = decode(body.replace(/\r?\n|\r/g, ''));
  convertToDoc(body).then(doc => {
    Packer.toBlob(doc).then((blob) => {
      const fileStream = streamSaver.createWriteStream("policy.docx");

      const readable = blob.stream();
      // more optimized
      // (Safari may have pipeTo but it's useless without the WritableStream)
      if (window.WritableStream && readable.pipeTo) {
        return readable.pipeTo(fileStream)
          .then(() => console.log('done writing'))
      }

      // less optimized
      const writer = fileStream.getWriter();
      const reader = readable.getReader();
      const pump = () => reader.read().then(res =>
            res.done 
            ? writer.close()
            : writer.write(res.value).then(pump)
          )
      pump();
    });
  });
  //return;
}

export default docxExport;

async function convertToDoc(body) {
  let doc = new Document(docConfig);
  const parsedBody = parse(body);
  let paragraphs = await doHtmlReplacements(parsedBody, doc);
  doc.addSection({
    properties: { },
    children: paragraphs
  });

  return doc;
}

async function doHtmlReplacements(parsedBody, doc) {
  let paragraphs = [];
  // We need to replace the allowed html elements with synonomous docx elements 
  // (ie h2 becomes a header with larger text)
  // <a href hreflang> <em> <strong> <cite> <blockquote cite> <code> <ul type> <ol start type> <li> <dl> <dt> <dd> 
  // <h2 id> <h3 id> <h4 id> <h5 id> <h6 id> <p> <br> <span component token> 
  // <img src alt height width data-entity-type data-entity-uuid data-align data-caption>
  for (let node of parsedBody) {
    let isCentered = node.attributes ? node.attributes.filter(attr => attr.value == 'text-align-center').length : 0;
    if (node.type === 'text' && !node.content.match(/^\s*$/)) {
      paragraphs.push(createParagraph({
        children: [ new TextRun({text: node.content.trim(), size: 24, color: '000000', font: { name: "Calibri" }}) ],
      }));
    } else {
      if (nodeHasChildren(node)) {
        if (node.tagName === 'ul' || node.tagName === 'ol') {
          paragraphs = await generateList(paragraphs, node.children, 0, doc);
        } else if (node.tagName === 'table') {
          paragraphs = generateTable(paragraphs, node.children[0].children, 0);
        } else if (node.tagName === 'img') {
          if (node.attributes) {
            let image, imagePara;
            let url = getImageNodeUrl(node);
            let buff = await generateImageBuffer(url);
            if (isCentered) {
              let imageSize;
              if (url.includes("http://") || url.includes("https://")) {
                imageSize = await probe(url);
              }
              image = imageSize ? Media.addImage(doc, buff, imageSize.width, imageSize.height) : Media.addImage(doc, buff);
              imagePara = createParagraph({ children: [image], alignment: AlignmentType.CENTER });
            } else {
              image = Media.addImage(doc, buff);          
              imagePara = createParagraph(image);
            }
            paragraphs.push(imagePara);
          }
        } else if (headers.includes(node.tagName)) {
          paragraphs.push(generateHeader(node, isCentered));
        } else {
          paragraphs = await generateChildren(paragraphs, node, doc);
        }
      }
    }
  }
  return paragraphs;
}

function generateHeader(node, centered=0) {
  let tmpObj = { ...tags[node.tagName] };
  if (node.children && node.children.length) {
    for (let child of node.children) {
      if (nodeIsText(child)) {
        tmpObj.text = child.content;
        break;
      }
    }
  }
  if (centered) tmpObj.alignment = AlignmentType.CENTER;
  return new Paragraph(tmpObj);
}

// generation functions
// these do most of the (html -> docx object) work
async function generateImageBuffer(url) {
  let imageReq = await axios.get(url, {responseType: 'arraybuffer'});
  let buff = Buffer.from(imageReq.data, 'binary');
  return buff;
}

function generateTable(paragraphs, tableRows, rowLevel) {
  for (let row of tableRows) {
    let tmpParaObj = { children: [] };
    if (rowLevel == 0) {
      tmpParaObj.heading = HeadingLevel.HEADING_6;
    }
    tmpParaObj.children = row.children.map((rowChild) => {
      return new TextRun({ 
        text: '\t' + rowChild.children[0].content, 
        color: '000000',
        font: { name: "Calibri" } 
      })
    });
    paragraphs.push(createParagraph(tmpParaObj));
    ++rowLevel;
  }
  paragraphs.push(createParagraph({ ...tags['break'] }));
  return paragraphs;
}

async function generateList(paragraphs, listItems, levelDepth, doc) {
  for (let item of listItems) {
    // top level <li> children
    if (item.tagName === 'li' && nodeHasChildren(item)) {
      let tmpObj = {bullet: { level: 0 }, color: '000000', text: '', size: 24, font: { name: "Calibri Light" }, children: []};
      tmpObj.bullet.level = levelDepth;
      // scan <li>'s children
      // we want the resulting structure to look like this
      // {li object}
      //   .children = [
      //     TextRun(Some span token)
      //     TextRun(" ")
      //     TextRun(body text of li)
      //     TextRun(another span token)
      //   ]
      for (let i = 0; i < item.children.length; i++) {
        let itemChild = item.children[i];
        let itemChildObj = { ...tags[itemChild.tagName] };
        if (itemChild.type === 'text') {
          if (nodeHasChildren(itemChild)) {
            itemChildObj.text = extractText(itemChild).trim().replace(/&shy;/g, "");
            tmpObj.children.push(new TextRun(itemChildObj));
          } else if (nodeIsText(itemChild)) {
            //itemChildObj.text = itemChild.content;
            tmpObj.children.push(new TextRun({text: itemChild.content.trim(), size: 24, color: '000000', font: { name: "Calibri Light" }}));
            tmpObj.children.push(new TextRun({text: ' ', size: 24, color: '000000', font: { name: "Calibri Light" }}));
          }
        } else if (itemChild.tagName === 'ul' && nodeHasChildren(itemChild)) {
          paragraphs.push(createParagraph(tmpObj)); 
          paragraphs = await generateList(paragraphs, itemChild.children, levelDepth+1, doc);
        } else if (nodeHasChildren(itemChild)) {
          // must be a span
          itemChildObj.text = extractText(itemChild).trim();
          tmpObj.children.push(new TextRun(itemChildObj));
          tmpObj.children.push(new TextRun({text: ' ', color: '000000', size: 24, font: { name: "Calibri Light" }}));
        }
      }
      // push this li object into the paragraphs
      paragraphs.push(createParagraph(tmpObj)); 
    }
  }
  return paragraphs;
}

async function generateChildren(paragraphs, node, doc) {
  let isHeader = nodeIsHeaderText(node);
  let isCentered = node.attributes ? node.attributes.filter(attr => attr.value == 'text-align-center').length : 0;
  let wasBreak = false;
  let children = node.children;
  for (let i = 0; i < children.length; i++) {
    let child = children[i];
    // A better strategy would be to build the tmpObj up, including a look ahead for the next element
    // so IF (text) THEN look for styled text next and append to tmpObj children
    // then we're just always doing paragraphs.push(createParagraph(tmpObj))
    // maybe I need to extend the functionality of createParagraph? 
 
    // detect and add page break
    if (child.attributes) {
      for (let attr of child.attributes) {
        if (attr.value == 'page-break') {
          paragraphs.push(createParagraph({ children: [new PageBreak()] }));
          wasBreak = true; 
          break; 
        } 
        if (attr.value == 'table-of-contents') {        
          const toc = 
            new TableOfContents("Summary", {
              hyperlink: true,
              headingStyleRange: "1-5",
              stylesWithLevels: [new StyleLevel("ImagineHeading1", 1)]
            });
          paragraphs.push(toc);
          wasBreak = true;
          break;
        }
      }
    }
    if (wasBreak) {
      wasBreak = false;
      continue;
    }

    if (child.type === 'text') {
      let retChildren = [];
      let textObj = {text: '', color: '000000', size: 24, font: { name: "Calibri Light" }};
      textObj.text = extractText(child);
      if (isHeader) tmpObj.italics = false;
      retChildren.push(new TextRun(textObj));
      retChildren.push(new TextRun({ text: ' ', size: 24, font: { name: "Calibri Light" } }));

      // look ahead for styled text
      for (i += 1; i < children.length; i++) {
        let innerChild = children[i];
        let innerTmpObj = { ...tags[innerChild.tagName] };
        if (innerChild.type === 'element' && !nodeIsStyledText(innerChild)) {
          break;
        }
        if (nodeIsStyledText(innerChild)) {
          let handledObj = handleStyledText(innerChild, innerTmpObj, retChildren.length > 0);
          retChildren.push(new TextRun(handledObj));
          retChildren.push(new TextRun({ text: ' ', size: 24, font: { name: "Calibri Light" } }));
        } else {
          innerTmpObj.text = extractText(innerChild);
          retChildren.push(new TextRun(innerTmpObj));
          retChildren.push(new TextRun({ text: ' ', size: 24, font: { name: "Calibri Light" } }));
        }
      }
      let pushParagraph = { children: retChildren }
      if (isCentered) pushParagraph.alignment = AlignmentType.CENTER;
      paragraphs.push(createParagraph(pushParagraph));
    } else if (child.type === 'element') { 
      let tmpObj = { ...tags[child.tagName] };
      if (tmpObj) {
        // handle stylized text
        if (nodeIsStyledText(child)) {
          let handledObj = handleStyledText(child, tmpObj, false);
          if (isCentered) handledObj.alignment = AlignmentType.CENTER;
          paragraphs.push(createParagraph(handledObj));
          continue;
        }

        // handle image
        if (child.tagName === 'img') {
          let image, imagePara;
          let url = getImageNodeUrl(child);
          let buff = await generateImageBuffer(url);
          if (isCentered) {
            let imageSize;
            if (url.includes("http://") || url.includes("https://")) {
              imageSize = await probe(url);
            }
            image = imageSize ? Media.addImage(doc, buff, imageSize.width, imageSize.height) : Media.addImage(doc, buff);
            imagePara = createParagraph({ children: [image], alignment: AlignmentType.CENTER });
          } else {
            image = Media.addImage(doc, buff);          
            imagePara = createParagraph(image);
          }
          paragraphs.push(imagePara); 
          continue;
        }

        // handle list
        if (child.tagName === 'ul' || child.tagName === 'ol') {
          paragraphs = await generateList(paragraphs, child.children, 0, doc);
          continue;
        }

        // make headers non-italic
        if (isHeader) { 
          tmpObj.italics = false;
        }

        // generate children
        if (child.children) paragraphs = await generateChildren(paragraphs, child, doc);

        // if there is text in this node, trim and add to object
        if (child.content) tmpObj.text = child.content.trim();

        if (isCentered) tmpObj.alignment = AlignmentType.CENTER;
        if (isHeader && tmpObj.text && tmpObj.text.length) {
          paragraphs.push(generateHeader(tmpObj))
        } else if (tmpObj.text && tmpObj.text.length) {
          paragraphs.push(createParagraph({ children: [new TextRun(tmpObj)] }))
        } else {
          paragraphs.push(createParagraph(tmpObj));        
        }
      }
    } // If it isn't one of these two types I think we just leave it out
  }
  return paragraphs;
}

// util functions
function extractText(spanChild) {
  let ret = [];
  if (spanChild.type === 'element' && nodeHasChildren(spanChild)) {
    for (let child of spanChild.children) {
      ret.push(extractText(child));
    }
  } else if (nodeIsText(spanChild)) {
    ret.push(spanChild.content.trim());
  }
  return ret.join(' ');
}

function createParagraph(obj) {
  obj.spacing = {
    before: 240, 
    after: 120 
  };
  return new Paragraph(obj);
}

function nodeHasChildren(node) {
  return node.children && node.children.length > 0;
}

function nodeIsText(node) {
  return node.type === 'text' && !node.content.match(/^\s*$/);
}

function nodeIsStyledText(node) {
  const styledTextElements = ['em','strong'];
  return styledTextElements.includes(node.tagName);
}

function handleStyledText(node, obj, middleOfString) {
  let content = middleOfString ? node.children[0].content.trim() : node.children[0].content;
  obj.text = node.children.length ? content : '';
  return obj;
}

function nodeIsHeaderText(node) {
  const styledTextElements = ['h1','h2','h3','h4','h5','h6'];
  return styledTextElements.includes(node.tagName);
}

function getImageNodeUrl(node) {
  if (node.attributes) {
    for (let attr of node.attributes) {
      if (attr.key == 'src') return attr.value;
    }
  }
  return '';
}
