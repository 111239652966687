import React, {useContext, useState} from "react";
import {graphql } from 'gatsby';
import LoadingOverlay from '../components/loading-overlay';
// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import Overview from "../components/dashboard/overview";
import ChapterCard from "../components/dashboard/chapter-card";

// question answering library
import { getSectionStatus, checkChapterComplete, checkAllSectionsComplete } from "../utils/questions"

// user context
import { wrapUserConsumer } from "../components/user-context";

import { NotifyContext } from "../components/notifications"

import ImagineSpinner from "../components/spinner"

function Dashboard({data, user, pageContext, location}){
  if(typeof user !== `undefined` && Object.keys(user.userData).length > 0){
    const langPath = pageContext.queriedLanguage === data.site.siteMetadata.langs.defaultLangcode ?
      '' : `/${pageContext.queriedLanguage}`;
    const rows = [];
    let row = [];
    let position = 0;
    let progress = {completed: 0, total: data.allNodeDashboard.edges[0].node.relationships.field_chapters.length};

    for(let nodeObj of data.allNodeDashboard.edges) {
      for(let chapterData of nodeObj.node.relationships.field_chapters) {
        let extractSectionComponentProp = (section) => {
          return {
            title: section.title,
            id: section.drupal_id,
            path: `${langPath}${chapterData.path.alias}${section.path.alias}`,
            status: getSectionStatus(user.userData, section)
          };
        };
        let hideNotRequiredSections = (section) => {
          return section.status !== 'not required';
        };
        const sections = chapterData.relationships.field_sections.map(extractSectionComponentProp).filter(hideNotRequiredSections);
        let chapterComplete = checkChapterComplete(user.userData, chapterData);

        let chapterReviewSection =  chapterData.relationships.hasOwnProperty('field_chapter_review_section') ?
          chapterData.relationships.field_chapter_review_section : false;

        if(chapterReviewSection){
          chapterReviewSection = extractSectionComponentProp(chapterReviewSection);
          if(!checkAllSectionsComplete(sections)){
            chapterReviewSection.status = 'not ready'
          }
          sections.push(chapterReviewSection);
        }

        // update counter for overview
        if(chapterComplete){
          progress.completed += 1;
        }

        let img = {
          fluid: chapterData.relationships.field_card_image.localFile.childImageSharp.fluid,
          alt: chapterData.field_card_image.alt,
        };

        row.push(
          <Col lg="4" key={chapterData.id}>
            <ChapterCard img={img}
                         chapterHeader={chapterData.field_header}
                         chapterTitle={chapterData.title}
                         sectionColumnLabel={chapterData.relationships.field_section_column_label.field_phrase_text.value}
                         statusColumnLabel={chapterData.relationships.field_status_column_label.field_phrase_text.value}
                         sections={sections}
                         isComplete={chapterComplete}
            />
          </Col>
        );
        if(position % 3 === 2){
          // every three columns, push a row of chapter cards
          let keyVal = 'row-' + position;
          rows.push(
            <Row className="card-wrapper" key={keyVal}>
              { row }
            </Row>
          );
          row = [];
        }
        position++;
      }
    }
    if(position % 3 !== 0){
      // if we havent completed the last row, fill it in with empty columns and push it
      for(var i = 0; i < 3 - (position % 3); i++){
        row.push(<Col lg="4"/>);
      }
      rows.push(
        <Row className="card-wrapper">
          { row }
        </Row>
      )
    }
    const books = [
      'nodeDefaultParentHandbook',
      'nodeDefaultProgramPolicy',
      'nodeDefaultStaffPolicy',
   ];

    //TODO: replace hardcoded docx and preview phrases with translatable dashboard content
    return (
      <>
        { !user.initialLoadingComplete || user.loading ? (<LoadingOverlay/>) : (<></>) }
        <Overview progress={progress} books={books} langPath={langPath} docXPhrase={'DOCX'} previewPhrase={"Preview"} data={data} />
        <Container className="mt--6" fluid>
          {rows}
        </Container>
      </>
    );
  }else{
    //this means we either don't have a user object or the userData is still loadiing
    return <div><ImagineSpinner /></div>
  }
}
export default wrapUserConsumer(Dashboard);
export const query = graphql`
  query ChapterQuery($queriedLanguage: String)  {
    nodeDefaultParentHandbook(queriedLanguage: {eq: $queriedLanguage}) {
      body {
        value
      }
    }
    nodeDefaultProgramPolicy(queriedLanguage: {eq: $queriedLanguage}) {
      body {
        value
      }
    }
    nodeDefaultStaffPolicy(queriedLanguage: {eq: $queriedLanguage}) {
      body {
        value
      }
    }
    allNodeDashboard(filter: {queriedLanguage: {eq: $queriedLanguage}}) {
      edges {
        node {
          relationships {
            field_chapters {
                id
                path {
                  alias
                }
                field_header 
                relationships {
                  field_card_image {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 1024) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                  field_completed_status_phrase {
                    field_phrase_text {
                      value
                    }
                  }
                  field_in_progress_status_phrase {
                    field_phrase_text {
                      value
                    }
                  }
                  field_not_started_status_phrase {
                    field_phrase_text {
                      value
                    }
                  }
                  field_section_column_label {
                    field_phrase_text {
                      value
                    }
                  }
                  field_status_column_label {
                    field_phrase_text {
                      value
                    }
                  }
                  field_sections {
                    path {
                      alias
                    } 
                    title
                    drupal_id
                    relationships {
                      field_questions {
                        ... on node__checkboxes_question {
                          drupal_id
                          internal {
                            type
                          }
                          relationships {
                            field_requirements {
                              drupal_id
                            }
                            field_policy_output {
                              field_token
                              field_replacement_text {
                                value
                              }
                            }
                            field_options {
                              drupal_id
                              relationships {
                                field_policy_output {
                                  field_replacement_text {
                                    value
                                  }
                                  field_token
                                }
                              }
                            }
                          }
                        }
                        ... on node__essay_question {
                          drupal_id
                          internal {
                            type
                          }
                          relationships {
                            field_requirements {
                              drupal_id
                            }
                            field_policy_output {
                              field_token
                              field_replacement_text {
                                value
                              }
                            }
                          }
                        }
                        ... on node__text_question {
                          drupal_id
                          internal {
                            type
                          }
                          relationships {
                            field_requirements {
                              drupal_id
                            }
                            field_policy_output {
                              field_token
                              field_replacement_text {
                                value
                              }
                            }
                          }
                        }
                        ... on node__radios_question {
                          drupal_id
                          internal {
                            type
                          }
                          relationships {
                            field_requirements {
                              drupal_id
                            }
                            field_policy_output {
                              field_token
                              field_replacement_text {
                                value
                              }
                            }
                            field_options {
                              drupal_id
                              relationships {
                                field_policy_output {
                                  field_replacement_text {
                                    value
                                  }
                                  field_token
                                }
                              }
                            }
                          }
                        }
                        ... on node__schedule_question {
                          drupal_id
                          internal {
                            type
                          }
                          relationships {
                            field_policy_output {
                              field_token
                              field_replacement_text {
                                value
                              }
                            }
                          }
                        }
                        ... on node__image_question {
                          drupal_id
                          internal {
                            type
                          }
                          relationships {
                            field_requirements {
                              drupal_id
                            }
                            field_policy_output {
                              field_token
                              field_replacement_text {
                                value
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  field_chapter_review_section{
                    path {
                      alias
                    } 
                    title
                    drupal_id
                    relationships {
                      field_questions {
                        ... on node__checkboxes_question {
                          drupal_id
                          internal {
                            type
                          }
                          relationships {
                            field_requirements {
                              drupal_id
                            }
                            field_policy_output {
                              field_token
                              field_replacement_text {
                                value
                              }
                            }
                            field_options {
                              drupal_id
                              relationships {
                                field_policy_output {
                                  field_replacement_text {
                                    value
                                  }
                                  field_token
                                }
                              }
                            }
                          }
                        }
                        ... on node__essay_question {
                          drupal_id
                          internal {
                            type
                          }
                          relationships {
                            field_requirements {
                              drupal_id
                            }
                            field_policy_output {
                              field_token
                              field_replacement_text {
                                value
                              }
                            }
                          }
                        }
                        ... on node__radios_question {
                          drupal_id
                          internal {
                            type
                          }
                          relationships {
                            field_requirements {
                              drupal_id
                            }
                            field_policy_output {
                              field_token
                              field_replacement_text {
                                value
                              }
                            }
                            field_options {
                              drupal_id
                              relationships {
                                field_policy_output {
                                  field_replacement_text {
                                    value
                                  }
                                  field_token
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                title
                field_card_image {
                  alt
                }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        langs {
          defaultLangcode
        }
      }
    }
  }
`;
