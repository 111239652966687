import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Progress,
  Row,
  Col,
  CardHeader,
  ListGroup,
  ListGroupItem
} from "reactstrap";

import {Link} from 'gatsby';
import docxExport from '../../utils/DocxExport.js';
// user context
import { wrapUserConsumer } from "../user-context";

function Overview({progress, books, langPath, previewPhrase, docXPhrase, user, data}){
  const bookMarkup = [];
  // https://gist.github.com/youssman/745578062609e8acac9f
  function camelCaseToDash( myStr ) {
    return myStr.replace( /([a-z])([A-Z])/g, '$1-$2' ).toLowerCase();
  }

  let iteration = 0;
  for(let book of books){
    iteration++;
    let bookPath = langPath + '/' + camelCaseToDash(book.substring(4));
    let bookDisplayName;
    switch(book){
      case 'nodeDefaultParentHandbook':
        bookDisplayName = 'Parent Book';
        break;
      case 'nodeDefaultProgramPolicy':
        bookDisplayName = 'Policy Book';
        break;
      case 'nodeDefaultStaffPolicy':
        bookDisplayName = 'Staff Handbook';
        break;
    }
    const borderClass = iteration >= books.length ? '' : 'border-bottom';
    const itemClasses = "bg-success p-3 " + borderClass;
    bookMarkup.push(
      <div className={itemClasses} key={book}>
        <Row className="align-items-center">
          <div className="col ml--1">
            <h5 className="mb-0 text-white">
              {bookDisplayName}
            </h5>
          </div>
          <Col className="col-auto">
            <Button color="primary" size="sm" type="button">
              <a href={bookPath} style={{color: "inherit"}}>{ previewPhrase }</a>
            </Button>
            <Button 
              color="primary" 
              size="sm" 
              type="button" 
              onClick={(event) => docxExport(data,book,user.userData,event)}>
              { docXPhrase }
            </Button>
          </Col>
        </Row>
      </div>
    )
  }
  return (
    <>
      <div className="header bg-info pb-6">
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col md="8" xl="8">
                <Card className="bg-gradient-primary border-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0 text-white"
                        >
                          Policy book chapters completed
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 text-white">
                        {progress.completed}/{progress.total}
                      </span>
                        <Progress
                          className="progress-md mt-3 mb-0"
                          max={progress.total}
                          value={progress.completed}
                          color="success"
                        />
                      </div>
                    </Row>
                  </CardBody>
                </Card>
                <div className="pr-5">
                  <p className="text-white mt-4">
                  </p>
                </div>
              </Col>
              <Col md="4" xl="4">
                <Card className="bg-success">
                  <CardHeader className="bg-success">
                    <h5 className="text-uppercase text-muted text-white mb-0">Policy books</h5>
                  </CardHeader>
                  <CardBody>
                    <div className="list my--3" flush>
                      { bookMarkup }
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}
export default wrapUserConsumer(Overview);
